<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="msaBlue white--text" @click="exportData">
        <v-icon> mdi-download </v-icon>
      </v-btn>
    </template>
    <span>Export</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    filters: {
      type: Object,
    },
    options: {
      type: Object,
    },
  },
  methods: {
    exportData() {
      const params = {
        filters: this.filters,
        options: this.options,
        loaderText: 'Exporting...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('download-task-report?format=xls', params, options)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Tasks Report.xls');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => error);
    },
  },
};
</script>
