<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          <v-row class="ma-1">
            <span>Filters</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon color="white"> mdi-close </v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row dense>
            <v-col cols="12"> User </v-col>
            <v-col cols="12">
              <CustomizedAutoComplete
                :value="filters.userIds"
                label="Select user"
                hide-details
                :items="users"
                item-text="fullname"
                clearable
                multiple
                deletable-chips
                @change="filters.userIds = $event"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"> Group </v-col>
            <v-col cols="12">
              <CustomizedAutoComplete
                :value="filters.labelIds"
                label="Select group"
                hide-details
                :items="labels"
                clearable
                multiple
                deletable-chips
                @change="filters.labelIds = $event"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">Task Name </v-col>
            <v-col>
              <v-text-field
                v-model="filters.taskName"
                outlined
                hide-details
                placeholder="Enter task name"
                dense
                clearable
                @click:clear="onInputClear('taskName')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"> Task Types </v-col>
            <v-col cols="12">
              <v-select
                v-model="filters.taskTypeIds"
                outlined
                dense
                placeholder="Select task type"
                hide-details
                :items="taskTypes"
                item-text="name"
                item-value="id"
                clearable
                multiple
                chips
                deletable-chips
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12"> Due Date </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="dueDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.dueDateFrom"
                    label="From"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.dueDateFrom"
                  no-title
                  @input="dueDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="dueDateToMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.dueDateTo"
                    label="To"
                    outlined
                    dense
                    clearable
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.dueDateTo"
                  no-title
                  @input="dueDateToMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">Status </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.taskStatusIds"
                mandatory
                multiple
                dense
                color="msaBlue"
                style="width: 100%"
              >
                <v-btn style="width: 33%" class="text-capitalize" :value="1">
                  Complete
                </v-btn>
                <v-btn style="width: 33%" class="text-capitalize" :value="2">
                  OverDue
                </v-btn>
                <v-btn style="width: 34%" class="text-capitalize" :value="3">
                  Outstanding
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="clear"> clear </v-btn>
          <v-btn class="msaBlue white--text" @click="apply"> apply </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="isFilterApplied ? 'orange' : 'msaBlue'"
          dark
          v-blur
          v-bind="attrs"
          v-on="on"
          @click="show"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </template>
      <span>Filter</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      users: [],
      labels: [],
      taskTypes: [
        { id: 1, name: 'Regular' },
        { id: 2, name: 'Document' },
        { id: 3, name: 'Training' },
      ],
      filters: {
        ...this.$constants.TASK_REPORT_FILTER,
      },
      original: {
        ...this.$constants.TASK_REPORT_FILTER,
      },
      isFilterApplied: false,
      dueDateFromMenu: false,
      dueDateToMenu: false,
    };
  },
  methods: {
    onInputClear(element) {
      this.filters[element] = '';
    },
    loadDependencies() {
      this.isLoading = true;
      return this.$axios
        .post('get-data-for-task-report-filter?format=json', {})
        .then((response) => {
          this.isLoading = false;
          this.users = response.data.users;
          this.labels = response.data.labels;
        });
    },
    setFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.updateFilterButtonState();
    },
    close() {
      this.filters = { ...this.orginal };
      this.dialog = false;
    },
    show() {
      this.orginal = { ...this.filters };
      this.dialog = true;
    },
    clear() {
      this.filters = JSON.parse(
        JSON.stringify(this.$constants.TASK_REPORT_FILTER),
      );

      this.apply();
    },
    apply() {
      this.filters = this.$helpers.fixFilterSpaces(
        this.$constants.TASK_REPORT_FILTER,
        this.filters,
      );
      this.$emit('applyFilters', this.filters);
      this.dialog = false;
    },
    updateFilterButtonState() {
      this.isFilterApplied =
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.TASK_REPORT_FILTER);
    },
  },
  mounted() {
    this.loadDependencies();
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
