<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <SearchBar
          @search="onSearch"
          ref="searchBar"
          searchLabel="Search by task name..."
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink"> </v-col>
      <v-col class="shrink"> </v-col>
      <v-col class="shrink">
        <TrainingTasksReportFilter
          @applyFilters="onApplyFilters"
          ref="filters"
        />
      </v-col>
      <v-col class="shrink">
        <TrainingTasksReportExport :filters="filters" :options="options" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text"> Tasks </v-card-title>
          <TrainingTasksReportTable
            ref="trainingTasks"
            @optionsUpdated="onOptionsUpdated"
            :filters="filters"
          ></TrainingTasksReportTable>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import TrainingTasksReportTable from '@/components/Reports/TrainingTasksReportTable.vue';
import TrainingTasksReportExport from '@/components/Reports/TrainingTasksReportExport.vue';
import TrainingTasksReportFilter from '@/components/Reports/TrainingTasksReportFilter.vue';
export default {
  name: 'TrainingTasksReportPage',
  components: {
    SearchBar,
    TrainingTasksReportFilter,
    TrainingTasksReportExport,
    TrainingTasksReportTable,
  },
  data() {
    return {
      filters: {
        ...this.$constants.TASK_REPORT_FILTER,
      },
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
        sortBy: ['employee'],
        sortDesc: [false],
      },
    };
  },
  methods: {
    onOptionsUpdated(options) {
      this.options = { ...options };
      this.applyFilter();
    },
    onSearch(data) {
      this.filters.taskName = data;
      this.applyFilter();
    },
    onApplyFilters(filters) {
      this.options.page = this.$constants.PAGINATION.DEFAULT_PAGE;
      this.filters = { ...filters };
      this.$refs.searchBar.search = filters.taskName;
      this.applyFilter();
    },
    loadQueryParams() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.options = this.$helpers.deserializeOptions(query, 'employee');
      this.$refs.searchBar.search = this.filters.taskName;
      this.$refs.filters.setFilters(this.filters);
      this.$refs.trainingTasks.setOptions(this.options);
      this.$refs.trainingTasks.setFilters(this.filters);
      this.$refs.trainingTasks.load();
    },
    applyFilter() {
      let params = this.$helpers.serializeFilter(this.filters);
      const options = this.$helpers.serializeOptions(this.options, 'employee');
      params = { ...params, ...options };
      this.$router
        .push({
          name: this.$route.name,
          query: params,
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadQueryParams();
  },
};
</script>
