<template>
  <span>
    <v-data-table
      :headers="headers"
      item-key="nowNumber"
      :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
      :items="tasks"
      :server-items-length="itemCount"
      :loading="loading"
      class="training-record-list"
      :options.sync="options"
      :no-data-text="filtersApplied ? 'No results found' : $vuetify.noDataText"
    >
      <template v-slot:[`item.employee`]="{ item }">
        <span
          @click="gotoEmployeeDetails(item)"
          class="msaBlue--text"
          :style="{ cursor: 'pointer' }"
        >
          {{ item.employee }}
        </span>
      </template>
      <template v-slot:[`item.dueDate`]="{ item }">
        {{ item.dueDate | filterAsLocalDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="goToDetail(item)" text color="msaBlue" v-on="on">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </span>
</template>

<script>
export default {
  computed: {
    filtersApplied() {
      return (
        JSON.stringify(this.filters) !==
        JSON.stringify(this.$constants.TASK_REPORT_FILTER)
      );
    },
  },
  data() {
    return {
      tasks: [],
      headers: [
        {
          text: 'User',
          value: 'employee',
          class: 'lightGrey',
          align: 'start',
          width: '150px',
        },
        {
          text: 'Group',
          value: 'group',
          width: '160px',
          class: 'lightGrey',
          align: 'start',
        },
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
          align: 'start',
        },
        {
          text: 'Task Type',
          value: 'typeName',
          width: '160px',
          class: 'lightGrey',
          align: 'center',
        },
        {
          text: 'Due Date',
          value: 'dueDate',
          width: '160px',
          class: 'lightGrey',
          align: 'center',
        },
        {
          text: 'Status',
          value: 'status',
          class: 'lightGrey',
          align: 'start',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'lightGrey',
          sortable: false,
          width: '90px',
        },
      ],
      isLoaded: false,
      loading: false,
      filters: {},
      options: {},
      itemCount: 0,
    };
  },
  watch: {
    options: {
      handler() {
        //needed to stop mutiple calls to the server when page loads
        if (this.isLoaded) {
          this.$emit('optionsUpdated', this.options);
        }
      },
      deep: true,
    },
  },
  methods: {
    goToDetail(task) {
      //this is here so the crumbs don't look wonky on the next page
      this.$store.commit('setSelectedTaskName', task.name);
      this.$router.push({
        name: 'UserTaskDetail',
        params: {
          id: task.userId,
          taskId: task.taskId,
        },
      });
    },
    setFilters(filters) {
      this.filters = { ...filters };
    },
    setOptions(options) {
      this.options = { ...options };
    },
    load() {
      this.loading = true;
      const params = {
        filters: this.filters,
        options: this.options,
      };
      this.$axios
        .post('get-training-tasks-report?format=json', params)
        .then((response) => {
          this.tasks = response.data.data;
          this.itemCount = response.data.count;
          this.loading = false;
          this.isLoaded = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    gotoEmployeeDetails(task) {
      this.$router.push({
        name: 'UserDetails',
        params: { id: task.userId },
      });
    },
  },
};
</script>

<style>
.training-record-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
